<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :hasDataPoint="isNoData && isClientChild && isCategoryPage">
              <template v-slot:tab>
                <ul class="tab">
                  <li class="tab-item"><button class="tab-btn" :class="{ 'is-active': isPointPage }" type="button" @click="tabChange(pointPage)">基本設定</button></li>
                  <li class="tab-item"><button class="tab-btn" :class="{ 'is-active': isCategoryPage }" type="button" @click="tabChange(categoryPage)">カテゴリー登録</button></li>
                </ul>
              </template>
              <template v-slot:body>
                <div class="slide-body">
                  <transition name="slide" mode="out-in">
                    <div v-show="isPointPage" class="tab-content">
                      <Panel type="sheet" customClass="panel-point">
                        <template v-slot:headline>ポイント基本設定</template>
                        <template v-slot:body>
                          <FormRow>
                            <template v-slot:label>金額に対する付与率</template>
                            <template v-slot:content>
                              <ul class="listGroup">
                                <li class="listGroup-item listGroup-point">
                                  <div class="form-group">
                                    <div class="form-group-item">
                                      <label class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="isCharge"
                                          v-model="form.isCharge"
                                          @change="resetChargeForm"
                                        />
                                        <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.isCharge }">購入時付与</span>
                                      </label>
                                    </div>
                                    <div class="form-group-item">
                                      <input
                                        :class="{ 'form-control': true, 'is-error': errors.chargePercent }"
                                        :disabled="!form.isCharge"
                                        type="text"
                                        v-decimal
                                        name="chargePercent"
                                        v-model="form.chargePercent"
                                      />
                                    </div>
                                    <div class="form-group-item">%</div>
                                  </div>
                                  <transition name="fade">
                                    <div v-show="form.isCharge">
                                      <FormRow :isCustomRow="true">
                                        <template v-slot:content>
                                          <ul class="listGroup">
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="dateChargePoint"
                                                      v-model="form.hasDateCharge"
                                                      @change="handleDateCharge"
                                                    />
                                                    <span class="form-check-label">期間限定ポイントアップ機能を利用する</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="form.hasDateCharge">
                                              <div class="form-group">
                                                <DateRange
                                                  field="chargeDate"
                                                  :value="form.chargeDate"
                                                  :errorField="errors.chargeDate"
                                                  @on-change="onChangeDateRange"
                                                />
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargePeriodPercent }"
                                                    type="text"
                                                    v-decimal
                                                    name="chargePeriodPercent"
                                                    v-model="form.chargePeriodPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">％追加</div>
                                                </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCpmParentEvent">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="chargePointCpm"
                                                      v-model="form.chargePointCpm"
                                                      @change="form.chargeCpmPercent = null"
                                                    />
                                                    <span class="form-check-label">CPMユーザー付与時</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargeCpmPercent }"
                                                    :disabled="!form.chargePointCpm"
                                                    type="text"
                                                    v-decimal
                                                    name="chargeCpmPercent"
                                                    v-model="form.chargeCpmPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCurrencyFlag">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="chargePointCurrency"
                                                      v-model="form.chargePointCurrency"
                                                      @change="form.chargeCurrencyPercent = null"
                                                    />
                                                    <span class="form-check-label">通貨管理付与時</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargeCurrencyPercent }"
                                                    :disabled="!form.chargePointCurrency"
                                                    type="text"
                                                    v-decimal
                                                    name="chargeCurrencyPercent"
                                                    v-model="form.chargeCurrencyPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                          </ul>
                                        </template>
                                      </FormRow>
                                    </div>
                                  </transition>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <div class="form-group">
                                    <div class="form-group-item">
                                      <label class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="isPayment"
                                          v-model="form.isPayment"
                                          @change="resetPaymentForm"
                                        />
                                        <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.isPayment }">支払時付与</span>
                                      </label>
                                    </div>
                                    <div class="form-group-item">
                                      <input
                                        :class="{ 'form-control': true, 'is-error': errors.paymentPercent }"
                                        :disabled="!form.isPayment"
                                        type="text"
                                        v-decimal
                                        name="paymentPercent"
                                        v-model="form.paymentPercent"
                                      />
                                    </div>
                                    <div class="form-group-item">%</div>
                                  </div>
                                  <transition name="fade">
                                    <div v-show="form.isPayment">
                                      <FormRow :isCustomRow="true">
                                        <template v-slot:content>
                                          <ul class="listGroup">
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="paymentFlag"
                                                      v-model="form.paymentFlag"
                                                    />
                                                    <span class="form-check-label">ポイント支払分にもポイントを付与する</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="hasDatePayment"
                                                      v-model="form.hasDatePayment"
                                                      @change="handleDatePayment"
                                                    />
                                                    <span class="form-check-label">期間限定ポイントアップ機能を利用する</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="form.hasDatePayment">
                                              <div class="form-group">
                                                <DateRange
                                                  field="paymentDate"
                                                  :value="form.paymentDate"
                                                  :errorField="errors.paymentDate"
                                                  @on-change="onChangeDateRange"
                                                />
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentPeriodPercent }"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentPeriodPercent"
                                                    v-model="form.paymentPeriodPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">％追加</div>
                                                </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCpmParentEvent">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="paymentPointCpm"
                                                      v-model="form.paymentPointCpm"
                                                      @change="form.paymentCpmPercent = null"
                                                    />
                                                    <span class="form-check-label">CPMユーザー利用時</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentCpmPercent }"
                                                    :disabled="!form.paymentPointCpm"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentCpmPercent"
                                                    v-model="form.paymentCpmPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="hasShopRole">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="paymentStoreFlag"
                                                      v-model="form.paymentStoreFlag"
                                                    />
                                                    <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.paymentStoreFlag }">
                                                      店舗限定ポイントアップ機能を利用する
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCurrencyFlag">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isPaymentCurrency"
                                                      v-model="form.isPaymentCurrency"
                                                      @change="form.paymentCurrencyPercent = null"
                                                    />
                                                    <span class="form-check-label">通貨管理消費時</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentCurrencyPercent }"
                                                    :disabled="!form.isPaymentCurrency"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentCurrencyPercent"
                                                    v-model="form.paymentCurrencyPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                          </ul>
                                        </template>
                                      </FormRow>
                                    </div>
                                  </transition>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <div class="form-group">
                                    <div class="form-group-item">
                                      <label class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="isRegisterCustomer"
                                          v-model="form.isRegisterCustomer"
                                          @change="resetRegistCustomer"
                                        />
                                        <span class="form-check-label">新規会員登録ポイントを付与する</span>
                                      </label>
                                    </div>
                                    <div class="form-group-item">
                                      <input
                                        :class="{ 'form-control': true, 'is-error': errors.registCustomerAmount }"
                                        :disabled="!form.isRegisterCustomer"
                                        type="tel"
                                        v-number
                                        name="registCustomerAmount"
                                        v-model="form.registCustomerAmount"
                                      />
                                    </div>
                                    <div class="form-group-item">ポイント</div>
                                  </div>
                                  <transition name="fade">
                                    <div v-show="form.isRegisterCustomer">
                                      <FormRow :isCustomRow="true">
                                        <template v-slot:content>
                                          <ul class="listGroup">
                                            <li class="listGroup-item listGroup-point" v-show="isUseCpmParentEvent">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isRegistCpmCustomer"
                                                      v-model="form.isRegistCpmCustomer"
                                                      @change="form.registCpmCustomerAmount = null"
                                                    />
                                                    <span class="form-check-label">CPMユーザー会員登録ポイントを付与する</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.registCpmCustomerAmount }"
                                                    :disabled="!form.isRegistCpmCustomer"
                                                    type="tel"
                                                    v-number
                                                    name="registCpmCustomerAmount"
                                                    v-trim
                                                    v-maxlength
                                                    v-model="form.registCpmCustomerAmount"
                                                  />
                                                </div>
                                                <div class="form-group-item">ポイント</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isRegistCustomerDate"
                                                      v-model="form.isRegistCustomerDate"
                                                      @change="handleRegisterCustomerDate"
                                                    />
                                                    <span class="form-check-label">新規会員登録ポイントを期間限定にする</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="form.isRegistCustomerDate">
                                              <div class="form-group">
                                                <DateRange
                                                  field="registCustomerDate"
                                                  :value="form.registCustomerDate"
                                                  :errorField="errors.registCustomerDate"
                                                  @on-change="onChangeDateRange"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </template>
                                      </FormRow>
                                    </div>
                                  </transition>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <div class="form-group">
                                    <div class="form-group-item">
                                      <label class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="isChargeWithTime"
                                          v-model="form.isChargeWithTime"
                                          :disabled="isRegistCustomerDisabled"
                                          @change="resetChargeWithTime"
                                        />
                                        <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.isChargeWithTime }">特別期間限定購入時付与</span>
                                      </label>
                                    </div>
                                  </div>
                                  <transition name="fade">
                                    <div v-show="form.isChargeWithTime">
                                      <FormRow :isCustomRow="true">
                                        <template v-slot:content>
                                          <ul class="listGroup">
                                            <li class="listGroup-item listGroup-point">
                                              ※特別期間限定購入時付与をリセットする場合は、特別期間限定購入時付与のチェックを外して登録するを選択してください。<br/>
                                              ※特別期間限定購入時付与のチェックを外して登録した場合は、発行ポイント上限で貯まったポイント、利用期間はリセットされます。
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <DateRange
                                                  type="dateTime"
                                                  field="timeDate"
                                                  :isConfigTimeNow="true"
                                                  :value="form.timeDate"
                                                  :errorField="errors.timeDate"
                                                  errorStartField="timeStart"
                                                  errorEndField="timeEnd"
                                                  @on-change="onChangeDateRange"
                                                />
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargeSpecialTimePercent }"
                                                    type="text"
                                                    v-decimal
                                                    name="chargeSpecialTimePercent"
                                                    v-model="form.chargeSpecialTimePercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">％</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCpmParentEvent">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isCpmPercent"
                                                      v-model="form.isCpmPercent"
                                                      @change="form.chargeSpecialTimeCpmPercent = null"
                                                    />
                                                    <span class="form-check-label">CPMユーザー付与率を利用する</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargeSpecialTimeCpmPercent }"
                                                    :disabled="!form.isCpmPercent"
                                                    type="text"
                                                    v-decimal
                                                    name="chargeSpecialTimeCpmPercent"
                                                    v-model="form.chargeSpecialTimeCpmPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCurrencyFlag">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isManualPercent"
                                                      v-model="form.isManualPercent"
                                                      @change="form.chargeSpecialTimeManualPercent = null"
                                                    />
                                                    <span class="form-check-label">通貨管理付与率を利用する</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.chargeSpecialTimeManualPercent }"
                                                    :disabled="!form.isManualPercent"
                                                    type="text"
                                                    v-decimal
                                                    name="chargeSpecialTimeManualPercent"
                                                    v-model="form.chargeSpecialTimeManualPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isLimitPointAdd"
                                                      v-model="form.isLimitPointAdd"
                                                      @change="form.limitPointAdd = null"
                                                    />
                                                    <span class="form-check-label">発行ポイント上限</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.limitPointAdd }"
                                                    :disabled="!form.isLimitPointAdd"
                                                    type="tel"
                                                    v-number
                                                    name="limitPointAdd"
                                                    v-model="form.limitPointAdd"
                                                  />
                                                </div>
                                                <div class="form-group-item">ポイント</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isLimitPointAddPerPerson"
                                                      v-model="form.isLimitPointAddPerPerson"
                                                      @change="form.limitPointAddPerPerson = null"
                                                    />
                                                    <span class="form-check-label">1人あたりの発行上限</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.limitPointAddPerPerson }"
                                                    :disabled="!form.isLimitPointAddPerPerson"
                                                    type="tel"
                                                    v-number
                                                    name="limitPointAddPerPerson"
                                                    v-model="form.limitPointAddPerPerson"
                                                  />
                                                </div>
                                                <div class="form-group-item">ポイント</div>
                                              </div>
                                            </li>
                                          </ul>
                                        </template>
                                      </FormRow>
                                    </div>
                                  </transition>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <div class="form-group">
                                    <div class="form-group-item">
                                      <label class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="isPaymentWithTime"
                                          v-model="form.isPaymentWithTime"
                                          :disabled="isPaymentWithTimeDisabled"
                                          @change="resetPaymentWithTime"
                                        />
                                        <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.isPaymentWithTime }">特別期間限定支払時付与</span>
                                      </label>
                                    </div>
                                  </div>
                                  <transition name="fade">
                                    <div v-show="form.isPaymentWithTime">
                                      <FormRow :isCustomRow="true">
                                        <template v-slot:content>
                                          <ul class="listGroup">
                                            <li class="listGroup-item listGroup-point">
                                              ※特別期間限定支払時付与をリセットする場合は、特別期間限定支払時付与のチェックを外して登録するを選択してください。<br/>
                                              ※特別期間限定支払時付与のチェックを外して登録した場合は、発行ポイント上限で貯まったポイント、利用期間はリセットされます。
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <DateRange
                                                  type="dateTime"
                                                  field="paymentTimeDate"
                                                  :isConfigTimeNow="true"
                                                  :value="form.paymentTimeDate"
                                                  :errorField="errors.paymentTimeDate"
                                                  errorStartField="paymentTimeStart"
                                                  errorEndField="paymentTimeEnd"
                                                  @on-change="onChangeDateRange"
                                                />
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentSpecialTimePercent }"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentSpecialTimePercent"
                                                    v-model="form.paymentSpecialTimePercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">％</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="paymentPointSpecialFlag"
                                                      v-model="form.paymentPointSpecialFlag"
                                                    />
                                                    <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.paymentPointSpecialFlag }">
                                                      ポイント支払分にもポイントを付与する
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCpmParentEvent">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isPaymentCpmPercent"
                                                      v-model="form.isPaymentCpmPercent"
                                                      @change="form.paymentSpecialTimeCpmPercent = null"
                                                    />
                                                    <span class="form-check-label">CPMユーザー付与率を利用する</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentSpecialTimeCpmPercent }"
                                                    :disabled="!form.isPaymentCpmPercent"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentSpecialTimeCpmPercent"
                                                    v-model="form.paymentSpecialTimeCpmPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="hasShopRole">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="paymentStoreSpecialFlag"
                                                      v-model="form.paymentStoreSpecialFlag"
                                                    />
                                                    <span :class="{ 'form-check-label': true, 'is-error-checkbox': errors.paymentStoreSpecialFlag }">
                                                      店舗限定ポイントアップ機能を利用する
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point" v-show="isUseCurrencyFlag">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isPaymentManualPercent"
                                                      v-model="form.isPaymentManualPercent"
                                                      @change="form.paymentSpecialTimeManualPercent = null"
                                                    />
                                                    <span class="form-check-label">通貨管理付与率を利用する</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.paymentSpecialTimeManualPercent }"
                                                    :disabled="!form.isPaymentManualPercent"
                                                    type="text"
                                                    v-decimal
                                                    name="paymentSpecialTimeManualPercent"
                                                    v-model="form.paymentSpecialTimeManualPercent"
                                                  />
                                                </div>
                                                <div class="form-group-item">%</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isLimitPointPaymentSpecial"
                                                      v-model="form.isLimitPointPaymentSpecial"
                                                      @change="form.limitPointPaymentSpecial = null"
                                                    />
                                                    <span class="form-check-label">発行ポイント上限</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.limitPointPaymentSpecial }"
                                                    :disabled="!form.isLimitPointPaymentSpecial"
                                                    type="tel"
                                                    v-number
                                                    name="limitPointPaymentSpecial"
                                                    v-model="form.limitPointPaymentSpecial"
                                                  />
                                                </div>
                                                <div class="form-group-item">ポイント</div>
                                              </div>
                                            </li>
                                            <li class="listGroup-item listGroup-point">
                                              <div class="form-group">
                                                <div class="form-group-item">
                                                  <label class="form-check">
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      name="isLimitPointPaymentPerPerson"
                                                      v-model="form.isLimitPointPaymentPerPerson"
                                                      @change="form.limitPointPaymentPerPerson = null"
                                                    />
                                                    <span class="form-check-label">1人あたりの発行上限</span>
                                                  </label>
                                                </div>
                                                <div class="form-group-item">
                                                  <input
                                                    :class="{ 'form-control': true, 'is-error': errors.limitPointPaymentPerPerson }"
                                                    :disabled="!form.isLimitPointPaymentPerPerson"
                                                    type="tel"
                                                    v-number
                                                    name="limitPointPaymentPerPerson"
                                                    v-model="form.limitPointPaymentPerPerson"
                                                  />
                                                </div>
                                                <div class="form-group-item">ポイント</div>
                                              </div>
                                            </li>
                                          </ul>
                                        </template>
                                      </FormRow>
                                    </div>
                                  </transition>
                                </li>
                                <li class="listGroup-item listGroup-point" v-show="form.isPaymentWithTime || form.isPayment">
                                  <FormRow>
                                    <template v-slot:label>ポイント加算タイミング</template>
                                    <template v-slot:content>
                                    <ul class="listGroup">
                                      <li class="listGroup-item listGroup-point">
                                        <div class="form-group">
                                          <div class="form-group-item">
                                            <p>支払確定時から</p>
                                          </div>
                                          <div class="form-group-item">
                                            <p v-if="!isEditCancelDateNumber">{{ newTimingDate }}</p>
                                            <input
                                              v-else
                                              :class="{ 'form-control': true, 'is-error': errors.timingDate }"
                                              type="tel"
                                              v-number
                                              name="tagName"
                                              v-model="form.timingDate"
                                            />
                                          </div>
                                          <div class="form-group-item">日</div>
                                        </div>
                                      </li>
                                    </ul>
                                    </template>
                                    <template v-slot:subLabelNote>
                                      <span class="text-note fz-note ml-15">※支払確定時から指定した日程経過後、次の朝6時にて反映されます。</span><br />
                                      <span class="text-note fz-note ml-15">※キャンセル可能期間より短い加算タイミングの設定はできません。</span><br />
                                      <span class="text-note fz-note ml-15">※未設定の場合は、キャンセル可能期間と同じ日程となります。</span><br />
                                      <span class="text-note fz-note ml-15">※0日の場合は、即時反映となります。</span>
                                    </template>
                                  </FormRow>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <FormRow>
                                    <template v-slot:label>ポイントの有効期限</template>
                                    <template v-slot:content>
                                      <ul class="listGroup">
                                        <li class="listGroup-item listGroup-point">
                                          <div class="form-group">
                                            <div class="form-group-item">
                                              <p>付与日から</p>
                                            </div>
                                            <div class="form-group-item">
                                              <input
                                                :class="{ 'form-control': true, 'is-error': errors.usedEndDate }"
                                                type="tel"
                                                v-number
                                                name="tagName"
                                                v-model="form.usedEndDate"
                                              />
                                            </div>
                                            <div class="form-group-item">日</div>
                                          </div>
                                        </li>
                                      </ul>
                                    </template>
                                  </FormRow>
                                </li>
                                <li class="listGroup-item listGroup-point">
                                  <FormRow>
                                    <template v-slot:label>ポイントの有効期限案内（任意）</template>
                                    <template v-slot:content>
                                      <ul class="listGroup">
                                        <li class="listGroup-item listGroup-point">
                                          <div class="form-group">
                                            <div class="form-group-item">
                                              <p>付与日から</p>
                                            </div>
                                            <div class="form-group-item">
                                              <input
                                                :class="{ 'form-control': true, 'is-error': errors.sendMailExpireAfterDays }"
                                                type="tel"
                                                v-number
                                                name="tagName"
                                                v-model="form.sendMailExpireAfterDays"
                                              />
                                            </div>
                                            <div class="form-group-item">日</div>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="form-content-row">
                                        <p>
                                          ※有効期限案内は有効期限以上の設定は不可<br/>
                                          ※設定有無関わらず、ポイント失効日の1日前に顧客にはメールで案内します
                                        </p>
                                      </div>
                                    </template>
                                  </FormRow>
                                </li>
                              </ul>
                            </template>
                          </FormRow>
                        </template>
                      </Panel>
                    </div>
                  </transition>
                  <transition name="slide" mode="out-in">
                    <div v-show="isCategoryPage" class="tab-content">
                      <Panel type="sheet" customClass="panel-point" :hasDataPoint="isNoData">
                        <template v-slot:headline>カテゴリー登録</template>
                        <template v-slot:body>
                          <section class="form-section category-add" :class="{ 'mb-0': isNoData }" v-if="isNotChildChildAndPointCommon">
                            <FormRow :required="true">
                              <template v-slot:label>カテゴリー名</template>
                              <template v-slot:labelNote>（20文字以内）</template>
                              <template v-slot:content>
                                <div class="form-content-row">
                                  <input
                                    :class="{ 'form-control': true, 'is-error': errors.categoryName }"
                                    type="text"
                                    name="categoryName"
                                    maxlength="20"
                                    v-trim
                                    v-maxlength
                                    v-model="formCategory.categoryName"
                                  />
                                </div>
                              </template>
                            </FormRow>
                            <ActionButton
                              v-if="isNotChildChildAndPointCommon"
                              :disabled="isMaximumCategory"
                              :class="{ 'btn btn-bd-main': true, 'bd-0': isMaximumCategory }"
                              :handle-submit="addCategory"
                              button-text="追加"
                            />
                          </section>
                          <section>
                            <div v-for="(item, index) in categoriesList" :key="item.index" class="category-item">
                              <FormRow>
                                <template v-slot:content>
                                  <div class="form-content-row">
                                    <input
                                      :class="{ 'form-control': true, 'is-error': errors[`categoryName${index}`] }"
                                      :disabled="!isNotChildChildAndPointCommon"
                                      type="text"
                                      name="name"
                                      maxlength="20"
                                      @blur="updateCategory(item.id, item.name, index)"
                                      v-trim
                                      v-maxlength
                                      v-model="item.name"
                                    />
                                  </div>
                                </template>
                              </FormRow>
                              <button
                                v-if="isNotChildChildAndPointCommon"
                                class="btn btn-bd-red"
                                @click="handleDeleted(item.id)"
                              >削除</button>
                            </div>
                            <div v-if="isNoData" :class="{ 'noData': true, 'custom-no-data': !isClientChild }">
                              <span>{{ noDataMessage }}</span>
                            </div>
                          </section>
                          <PaginationLog
                            v-if="isCommonAllEvent"
                            ref="pagination"
                            :subdomain="subdomain"
                            :listLength="categoriesCount"
                            :modulePath="modulePath"
                            :limit="catagoryLimit"
                          ></PaginationLog>
                        </template>
                      </Panel>
                    </div>
                  </transition>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <Modal @close="closeModal()" v-if="modal">
        <template v-slot:headline>削除</template>
        <template v-slot:body>
          <p class="description text-align-center">
            選択したカテゴリーを削除しますか？
          </p>
          <ul class="listGrid justify-content-center">
            <li class="listGrid-item">
              <button
                class="btn btn-lg btn-white"
                type="button"
                @click="closeModal()"
              >
                キャンセル
              </button>
            </li>
            <li class="listGrid-item">
              <ActionButton
                class="btn btn-lg btn-red"
                :handle-submit="deleteCategory"
                button-text="削除する"
              />
            </li>
          </ul>
        </template>
      </Modal>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid" v-if="isPointPage && isNotChildChildAndPointCommon">
          <li class="listGrid-item pos-end">
            <ActionButton class="btn btn-main" :handle-submit="handleSettingPoint" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { COMMON_MESSAGE } from '@/config/message';
//mixins
import modal from '@/mixins/plugin/modal';
//component
import FormRow from '@/components/FormRow.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import DateRange from '@/components/DateRange.vue';
//mixins
import error from '@/mixins/plugin/error';
import nav from '@/mixins/nav/point';
//modal
import Modal from '@/components/Modal.vue';
//helpers
import { isDateWithMinMaxValue, isDecimal, isNotPeriodPoint, isDateWithSameAsValue } from '@/helpers/validators';

const pointPage = 1;
const categoryPage = 2;
const catagoryLimit = 10;

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      parentModulePath: 'parentPoint/getCategoriesList',
      childModulePath: 'childPoint/getCategoriesList',
      form: {
        chargeDate: {
          start: '',
          end: ''
        },
        paymentDate: {
          start: '',
          end: ''
        },
        registCustomerDate: {
          start: '',
          end: ''
        },
        timeDate: {
          start: '',
          end: '',
        },
        paymentTimeDate: {
          start: '',
          end: '',
        }
      },
      formCategory: {},
      tabActive: pointPage,
      categoryId: null,
      maxCategoryChild: 10,
      maxCategoryCommon: 50,
      pointPage,
      categoryPage,
      catagoryLimit,
      prefixs: ['chargeDate', 'paymentDate', 'registCustomerDate', 'timeDate', 'paymentTimeDate'],
    };
  },
  components: {
    FormRow,
    PaginationLog,
    Modal,
    DateRange
  },
  mixins: [modal, error, nav],
  computed: {
    ...mapGetters({
      lists: 'coupon/couponList',
      couponCount: 'coupon/couponCount',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      parentEventDetail: 'event/parentEventDetail',
      childEventDetail: 'event/childEventDetail',
      isOnlyChildEvent: 'event/isOnlyChildEvent',
      isCommonAllEvent: 'event/isCommonAllEvent',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      isUseCurrencyFlagParentEvent: 'event/isUseCurrencyFlagParentEvent',
      hasCurrency: 'event/hasCurrency',
      parentCategoryCount: 'parentPoint/categoryCount',
      childCategoryCount: 'childPoint/categoryCount',
    }),
    isPointPage() {
      return this.tabActive === this.pointPage;
    },
    isCategoryPage() {
      return this.tabActive === this.categoryPage;
    },
    categoriesCount() {
      return this.isPointCommon ? this.parentCategoryCount : this.childCategoryCount;
    },
    isMaximumCategory() {
      const maxCategory = this.isCommonAllEvent ? this.maxCategoryCommon : this.maxCategoryChild;
      return this.categoriesCount === maxCategory;
    },
    modulePath() {
      return this.isPointCommon ? this.parentModulePath : this.childModulePath;
    },
    isNoData() {
      return !this.categoriesList?.length;
    },
    noDataMessage() {
      return this.unAuthorized ? COMMON_MESSAGE.unAuthorizedTable : COMMON_MESSAGE.noData;
    },
    isEditCancelDateNumber() {
      return this.isPointCommon ? this.parentEventDetail?.canChangeTimingDate : this.childEventDetail?.canChangeTimingDate;
    },
    isUseCurrencyFlag() {
      return this.isPointCommon ? this.isUseCurrencyFlagParentEvent : this.hasCurrency;
    },
    newTimingDate() {
      return this.pointDetail?.timingDate;
    },
    isRegistCustomerDisabled() {
      return !!this.pointDetail?.chargeSpecialTimePercent && !!this.pointDetail?.hasPlannedPointCharge;
    },
    isPaymentWithTimeDisabled() {
      return !!this.pointDetail?.paymentSpecialTimePercent && !!this.pointDetail?.hasPlannedPointPayment;
    }
  },
  validations(){
    let formCategory = {
      categoryName : { required, maxLength: maxLength(20) }
    };
    let form = {
      isCharge: {},
      chargePercent: {},
      chargeDate: {},
      paymentDate: {},
      registCustomerDate: {},
      chargePeriodPercent: {},
      chargeCpmPercent: {},
      chargeCurrencyPercent: {},
      isPayment: {},
      paymentPercent: {},
      paymentPeriodPercent: {},
      paymentCpmPercent: {},
      paymentCurrencyPercent: {},
      registCustomerAmount: {},
      registCpmCustomerAmount: {},
      timingDate: { dateWithMinMaxValue: isDateWithMinMaxValue(0, 9999) },
      usedEndDate: {},
      sendMailExpireAfterDays: {},
      timeDate: {},
      chargeSpecialTimePercent: {},
      chargeSpecialTimeCpmPercent: {},
      chargeSpecialTimeManualPercent: {},
      limitPointAdd: {},
      limitPointAddPerPerson: {},
      paymentTimeDate: {},
      paymentSpecialTimePercent: {},
      paymentPointSpecialFlag: {},
      paymentSpecialTimeCpmPercent: {},
      paymentStoreSpecialFlag: {},
      paymentSpecialTimeManualPercent: {},
      limitPointPaymentSpecial: {},
      limitPointPaymentPerPerson: {},
    };
    form = {
      ...form,
      formPointRequired: (val) => {
        let result = true;
        if (this.form.isCharge && !val.chargePercent) {
          this.errors.chargePercent = true;
          result = false;
        }
        if (this.form.hasDateCharge && !val.chargePeriodPercent) {
          this.errors.chargePeriodPercent = true;
          result = false;
        }
        if (this.form.hasDatePayment && !val.paymentPeriodPercent) {
          this.errors.paymentPeriodPercent = true;
          result = false;
        }
        if (this.form.chargePointCpm && !val.chargeCpmPercent) {
          this.errors.chargeCpmPercent = true;
          result = false;
        }
        if (this.form.chargePointCurrency && !val.chargeCurrencyPercent) {
          this.errors.chargeCurrencyPercent = true;
          result = false;
        }
        if (this.form.isPayment && !val.paymentPercent) {
          this.errors.paymentPercent = true;
          result = false;
        }
        if (this.form.paymentPointCpm && !val.paymentCpmPercent) {
          this.errors.paymentCpmPercent = true;
          result = false;
        }
        if (this.form.isPaymentCurrency && !val.paymentCurrencyPercent) {
          this.errors.paymentCurrencyPercent = true;
          result = false;
        }
        if (this.form.isRegisterCustomer && !val.registCustomerAmount) {
          this.errors.registCustomerAmount = true;
          result = false;
        }
        if (!result) {
          this.$message.formPointRequired();
        }
        return result
      },
      invalidTextboxPoint: (val) => {
        let result = true;
        if (this.form.isRegisterCustomer && val.registCustomerAmount && (val.registCustomerAmount < 1 || val.registCustomerAmount > 999999)) {
          this.errors.registCustomerAmount = true;
          result = false;
        }
        if (this.form.isRegistCpmCustomer && val.registCpmCustomerAmount && (val.registCpmCustomerAmount < 1 || val.registCpmCustomerAmount > 999999)) {
          this.errors.registCpmCustomerAmount = true;
          result = false;
        }
        if (this.form.isLimitPointAdd && val.limitPointAdd && (val.limitPointAdd < 1 || val.limitPointAdd > 999999)) {
          this.errors.limitPointAdd = true;
          result = false;
        }
        if (this.form.isLimitPointAddPerPerson && val.limitPointAddPerPerson && (val.limitPointAddPerPerson < 1 || val.limitPointAddPerPerson > 999999)) {
          this.errors.limitPointAddPerPerson = true;
          result = false;
        }
        if (this.form.isLimitPointPaymentSpecial && val.limitPointPaymentSpecial && (val.limitPointPaymentSpecial < 1 || val.limitPointPaymentSpecial > 999999)) {
          this.errors.limitPointPaymentSpecial = true;
          result = false;
        }
        if (this.form.isLimitPointPaymentPerPerson && val.limitPointPaymentPerPerson && (val.limitPointPaymentPerPerson < 1 || val.limitPointPaymentPerPerson > 999999)) {
          this.errors.limitPointPaymentPerPerson = true;
          result = false;
        }
        if (!result) {
          this.$message.showError('invalidTextboxPoint', 'max', '999,999');
        }
        return result;
      },
      invalidPercent: (val) => {
        let result = true;
        if (this.form.isCharge && val.chargePercent && this.checkValidDemical(val.chargePercent)) {
          this.errors.chargePercent = true;
          result = false;
        }
        if (this.form.hasDateCharge && val.chargePeriodPercent && this.checkValidDemical(val.chargePeriodPercent)) {
          this.errors.chargePeriodPercent = true;
          result = false;
        }
        if (this.form.hasDatePayment && val.paymentPeriodPercent && this.checkValidDemical(val.paymentPeriodPercent)) {
          this.errors.paymentPeriodPercent = true;
          result = false;
        }
        if (this.form.chargePointCpm && val.chargeCpmPercent && this.checkValidDemical(val.chargeCpmPercent)) {
          this.errors.chargeCpmPercent = true;
          result = false;
        }
        if (this.form.chargePointCurrency && val.chargeCurrencyPercent && this.checkValidDemical(val.chargeCurrencyPercent)) {
          this.errors.chargeCurrencyPercent = true;
          result = false;
        }
        if (this.form.isPayment && val.paymentPercent && this.checkValidDemical(val.paymentPercent)) {
          this.errors.paymentPercent = true;
          result = false;
        }
        if (this.form.paymentPointCpm && val.paymentCpmPercent && this.checkValidDemical(val.paymentCpmPercent)) {
          this.errors.paymentCpmPercent = true;
          result = false;
        }
        if (this.form.isPaymentCurrency && val.paymentCurrencyPercent && this.checkValidDemical(val.paymentCurrencyPercent)) {
          this.errors.paymentCurrencyPercent = true;
          result = false;
        }
        if (this.form.isChargeWithTime && val.chargeSpecialTimePercent && this.checkValidDemical(val.chargeSpecialTimePercent)) {
          this.errors.chargeSpecialTimePercent = true;
          result = false;
        }
        if (this.form.isPaymentWithTime && val.paymentSpecialTimePercent && this.checkValidDemical(val.paymentSpecialTimePercent)) {
          this.errors.paymentSpecialTimePercent = true;
          result = false;
        }
        if (this.form.isCpmPercent && val.chargeSpecialTimeCpmPercent && this.checkValidDemical(val.chargeSpecialTimeCpmPercent)) {
          this.errors.chargeSpecialTimeCpmPercent = true;
          result = false;
        }
        if (this.form.isManualPercent && val.chargeSpecialTimeManualPercent && this.checkValidDemical(val.chargeSpecialTimeManualPercent)) {
          this.errors.chargeSpecialTimeManualPercent = true;
          result = false;
        }
        if (this.form.isPaymentCpmPercent && val.paymentSpecialTimeCpmPercent && this.checkValidDemical(val.paymentSpecialTimeCpmPercent)) {
          this.errors.paymentSpecialTimeCpmPercent = true;
          result = false;
        }
        if (this.form.isPaymentManualPercent && val.paymentSpecialTimeManualPercent && this.checkValidDemical(val.paymentSpecialTimeManualPercent)) {
          this.errors.paymentSpecialTimeManualPercent = true;
          result = false;
        }
        if (!result) {
          this.$message.invalidPercent();
        }
        return result;
      },
    }
    if (this.form.usedEndDate && (this.form?.usedEndDate < 1 || this.form?.usedEndDate > 9999)) {
      form.usedEndDate = { dateWithMinMaxValue: isDateWithMinMaxValue(1, 9999) };
    } else if (this.form.sendMailExpireAfterDays) {
      let isInValidUsedEndDate = false;
      if (Number(this.form?.usedEndDate) === 3) {
        isInValidUsedEndDate = this.form?.sendMailExpireAfterDays > 1 || Number(this.form?.sendMailExpireAfterDays) === 0;
      } else if (Number(this.form?.usedEndDate) < 3) {
        isInValidUsedEndDate = this.form?.sendMailExpireAfterDays >= 1 || Number(this.form?.sendMailExpireAfterDays) === 0;
      }
      if (!Number(this.form.usedEndDate)) {
        form.sendMailExpireAfterDays = { validPeriodPoint: () => false };
      } else if (this.form?.usedEndDate && isInValidUsedEndDate) {
        form.sendMailExpireAfterDays = { notPeriodPoint: isNotPeriodPoint() };
      } else if (this.form?.usedEndDate >= 1 && this.form?.usedEndDate <= 9999) {
        form.sendMailExpireAfterDays = { dateWithMinMaxValue: isDateWithMinMaxValue(1, Number(this.form?.usedEndDate) - 2) };
      }
    }
    if (this.form.timingDate > 0 && (this.form.timingDate < this.parentEventDetail?.cancelDateNumber)) {
      form.timingDate = { timingDateWithMaxValue: () => false };
    }
    if (this.form.hasDateCharge || this.form.hasDatePayment || this.form.isRegistCustomerDate || this.form.isChargeWithTime) {
      form = {
        ...form,
        invalidCalendar: (val) => {
          let result = true;
          if (this.form.hasDateCharge && (!val.chargeDate.start || !val.chargeDate.end)) {
            this.errors.chargeDate.start = !val.chargeDate.start;
            this.errors.chargeDate.end = !val.chargeDate.end;
            result = false;
          }
          if (this.form.hasDatePayment && (!val.paymentDate.start || !val.paymentDate.end)) {
            this.errors.paymentDate.start = !val.paymentDate.start;
            this.errors.paymentDate.end = !val.paymentDate.end;
            result = false;
          }
          if (this.form.isRegistCustomerDate && (!val.registCustomerDate.start || !val.registCustomerDate.end)) {
            this.errors.registCustomerDate.start = !val.registCustomerDate.start;
            this.errors.registCustomerDate.end = !val.registCustomerDate.end;
            result = false;
          }
          if (!result) {
            this.$message.invalidCalendar();
          }
          return result;
        }
      }
    }
    if (this.form.isCharge && this.form.isChargeWithTime) {
      form = {
        ...form,
        invalidChargePoint: (val) => {
          let result = true;
          if (val.isCharge && val.isChargeWithTime) {
            this.errors.isCharge = true;
            this.errors.isChargeWithTime = true;
            result = false;
          }
          if (!result) {
            this.$message.invalidChargePoint();
          }
          return result;
        }
      }
    }
    if (this.form.isPayment && this.form.isPaymentWithTime) {
      form = {
        ...form,
        invalidPaymentPoint: (val) => {
          let result = true;
          if (val.isPayment && val.isPaymentWithTime) {
            this.errors.isPayment = true;
            this.errors.isPaymentWithTime = true;
            result = false;
          }
          if (!result) {
            this.$message.invalidPaymentPoint();
          }
          return result;
        }
      }
    }
    if (this.form.isChargeWithTime) {
      if (!this.form.timeDate.start || !this.form.timeDate.end) {
        form.timeDate = {
          start: { selectRequired: required },
          end: { selectRequired: required }
        }
      } else {
        form.timeDate = {
          start: { selectRequired: required },
          end: {
            selectRequired: required,
            notSameAsDateTime: isDateWithSameAsValue(this.form.timeDate?.start, this.form.timeDate?.end)
          }
        }
      }
      form.chargeSpecialTimePercent = { required };
      if (this.form.isCpmPercent) form.chargeSpecialTimeCpmPercent = { required };
      if (this.form.isManualPercent) form.chargeSpecialTimeManualPercent = { required };
      if (this.form.isLimitPointAdd) form.limitPointAdd = { required };
      if (this.form.isLimitPointAddPerPerson) form.limitPointAddPerPerson = { required };
      if (this.form.limitPointAddPerPerson && this.form.limitPointAdd && (Number(this.form.limitPointAddPerPerson) > Number(this.form.limitPointAdd))) {
        form.limitPointAddPerPerson = { notMoreThanPointAdd: () => false };
      }
      if (this.form.isLimitPointAdd && (!!this.form.limitPointAdd && (!!this.pointDetail?.limitPointAdd || !!this.pointDetail?.totalPointAdd))) {
        if (Number(this.form.limitPointAdd) < this.pointDetail?.limitPointAdd) {
          form.limitPointAdd = { limitWithPointAdd: () => false };
        }
        if (Number(this.form.limitPointAdd) < this.pointDetail?.totalPointAdd) {
          form.limitPointAdd = { limitWithPointAddTotal: () => false };
        }
      }
    }
    if (this.form.isPaymentWithTime) {
      if (!this.form.paymentTimeDate.start || !this.form.paymentTimeDate.end) {
        form.paymentTimeDate = {
          start: { selectRequired: required },
          end: { selectRequired: required }
        }
      } else {
        form.paymentTimeDate = {
          start: { selectRequired: required },
          end: {
            selectRequired: required,
            notSameAsPaymentDateTime: isDateWithSameAsValue(this.form.paymentTimeDate?.start, this.form.paymentTimeDate?.end)
          }
        }
      }
      form.paymentSpecialTimePercent = { required };
      if (this.form.isPaymentCpmPercent) form.paymentSpecialTimeCpmPercent = { required };
      if (this.form.isPaymentManualPercent) form.paymentSpecialTimeManualPercent = { required };
      if (this.form.isLimitPointPaymentSpecial) form.limitPointPaymentSpecial = { required };
      if (this.form.isLimitPointPaymentPerPerson) form.limitPointPaymentPerPerson = { required };
      if (this.form.limitPointPaymentPerPerson && this.form.limitPointPaymentSpecial && (Number(this.form.limitPointPaymentPerPerson) > Number(this.form.limitPointPaymentSpecial))) {
        form.limitPointPaymentPerPerson = { notMoreThanPointAdd: () => false };
      }
      if (this.form.islimitPointPaymentSpecial && (!!this.form.limitPointPaymentSpecial && (!!this.pointDetail?.limitPointPaymentSpecial || !!this.pointDetail?.totalPointAddPayment))) {
        if (Number(this.form.limitPointPaymentSpecial) < this.pointDetail?.limitPointPaymentSpecial) {
          form.limitPointPaymentSpecial = { limitWithPointAdd: () => false };
        }
        if (Number(this.form.limitPointPaymentSpecial) < this.pointDetail?.totalPointAddPayment) {
          form.limitPointPaymentSpecial = { limitWithPointAddTotal: () => false };
        }
      }
    }
    return this.isPointPage  ? { form } : { formCategory };
  },
  watch: {
    pointDetail(val) {
      if (val) {
        this.getFormPointDetail();
      }
    },
    'form.isCharge'(val) {
      if (!val) {
        this.errors.chargePercent = false;
        this.errors.chargeDate = {
          start: false,
          end: false,
        };
        this.errors.chargePeriodPercent = false;
        this.errors.chargeCpmPercent = false;
        this.errors.chargeCurrencyPercent = false;
      }
    },
    'form.hasDateCharge'(val) {
      if (!val) {
        this.errors.chargeDate = {
          start: false,
          end: false,
        };
        this.errors.chargePeriodPercent = false;
      }
    },
    'form.chargePointCpm'(val) {
      if (!val) {
        this.errors.chargeCpmPercent = false;
      }
    },
    'form.chargePointCurrency'(val) {
      if (!val) {
        this.errors.chargeCurrencyPercent = false;
      }
    },
    'form.isPayment'(val) {
      if (!val) {
        this.errors.paymentPercent = false;
        this.errors.paymentDate = {
          start: false,
          end: false,
        };
        this.errors.paymentPeriodPercent = false;
        this.errors.paymentCpmPercent = false;
        this.errors.paymentCurrencyPercent = false;
      }
    },
    'form.hasDatePayment'(val) {
      if (!val) {
        this.errors.paymentDate = {
          start: false,
          end: false,
        };
        this.errors.paymentPeriodPercent = false;
      }
    },
    'form.paymentPointCpm'(val) {
      if (!val) {
        this.errors.paymentCpmPercent = false;
      }
    },
    'form.isPaymentCurrency'(val) {
      if (!val) {
        this.errors.paymentCurrencyPercent = false;
      }
    },
    'form.isRegisterCustomer'(val) {
      if (!val) {
        this.errors.registCustomerAmount = false;
        this.errors.registCpmCustomerAmount = false;
        this.errors.registCustomerDate = {
          start: false,
          end: false,
        };
      }
    },
    'form.isRegistCpmCustomer'(val) {
      if (!val) {
        this.errors.registCpmCustomerAmount = false;
      }
    },
    'form.isRegistCustomerDate'(val) {
      if (!val) {
        this.errors.registCustomerDate = {
          start: false,
          end: false,
        };
      }
    },
    'form.isChargeWithTime'(val) {
      if (!val) {
        this.resetChargeWithTime();
        this.errors.timeDate = {
          start: false,
          end: false,
        };
        this.errors.chargeSpecialTimePercent = false;
        this.errors.chargeSpecialTimeCpmPercent = false;
        this.errors.chargeSpecialTimeManualPercent = false;
        this.errors.limitPointAdd = false;
        this.errors.limitPointAddPerPerson = false;
      }
    },
    'form.isCpmPercent'(val) {
      if (!val) {
        this.errors.chargeSpecialTimeCpmPercent = false;
      }
    },
    'form.isManualPercent'(val) {
      if (!val) {
        this.errors.chargeSpecialTimeManualPercent = false;
      }
    },
    'form.isLimitPointAdd'(val) {
      if (!val) {
        this.errors.limitPointAdd = false;
      }
    },
    'form.isLimitPointAddPerPerson'(val) {
      if (!val) {
        this.errors.limitPointAddPerPerson = false;
      }
    },
    'form.isPaymentWithTime'(val) {
      if (!val) {
        this.resetPaymentWithTime();
        this.errors.paymentTimeDate = {
          start: false,
          end: false,
        };
        this.errors.paymentSpecialTimePercent = false;
        this.errors.paymentSpecialTimeCpmPercent = false;
        this.errors.paymentSpecialTimeManualPercent = false;
        this.errors.limitPointPaymentSpecial = false;
        this.errors.limitPointPaymentPerPerson = false;
      }
    },
    'form.isPaymentCpmPercent'(val) {
      if (!val) {
        this.errors.paymentSpecialTimeCpmPercent = false;
      }
    },
    'form.isPaymentManualPercent'(val) {
      if (!val) {
        this.errors.paymentSpecialTimeManualPercent = false;
      }
    },
    'form.isLimitPointPaymentSpecial'(val) {
      if (!val) {
        this.errors.limitPointPaymentSpecial = false;
      }
    },
    'form.isLimitPointPaymentPerPerson'(val) {
      if (!val) {
        this.errors.limitPointPaymentPerPerson = false;
      }
    },
  },
  methods: {
    initFormPoint() {
      this.form = {
        isCharge: false,
        chargePercent: null,
        hasDateCharge: false,
        chargeDate: {
          start: '',
          end: '',
        },
        paymentDate: {
          start: '',
          end: '',
        },
        registCustomerDate: {
          start:'',
          end: '',
        },
        chargePeriodPercent: null,
        chargePointCpm: false,
        chargeCpmPercent: null,
        chargePointCurrency: false,
        chargeCurrencyPercent: null,
        isPayment: false,
        paymentPercent: null,
        paymentFlag: false,
        hasDatePayment: false,
        paymentPeriodPercent: null,
        paymentPointCpm: false,
        paymentCpmPercent: null,
        paymentStoreFlag: false,
        isPaymentCurrency: false,
        paymentCurrencyPercent: null,
        isRegisterCustomer: false,
        registCustomerAmount: null,
        isRegistCpmCustomer: false,
        registCpmCustomerAmount: null,
        isRegistCustomerDate: false,
        timingDate: null,
        usedEndDate: null,
        sendMailExpireAfterDays: null,
        isChargeWithTime: false,
        timeDate: {
          start: '',
          end: '',
        },
        isCpmPercent: false,
        isManualPercent: false,
        isLimitPointAdd: false,
        isLimitPointAddPerPerson: false,
        chargeSpecialTimeCpmPercent: null,
        chargeSpecialTimeManualPercent: null,
        chargeSpecialTimePercent: null,
        limitPointAdd: null,
        limitPointAddPerPerson: null,
        isPaymentWithTime: false,
        paymentSpecialTimePercent: null,
        paymentPointSpecialFlag: false,
        paymentTimeDate: {
          start: '',
          end: '',
        },
        isPaymentCpmPercent: false,
        paymentSpecialTimeCpmPercent: null,
        paymentStoreSpecialFlag: false,
        isPaymentManualPercent: false,
        paymentSpecialTimeManualPercent: null,
        isLimitPointPaymentSpecial: false,
        limitPointPaymentSpecial: null,
        isLimitPointPaymentPerPerson: false,
        limitPointPaymentPerPerson: null,
      }
    },
    initFormCategory() {
      this.formCategory = {
        categoryName: '',
      }
    },
    resetChargeForm() {
      this.form.hasDateCharge = false;
      this.form.chargeDate.start = '';
      this.form.chargeDate.end = '';
      this.form.chargePercent = null;
      this.form.chargePeriodPercent = null;
      this.form.chargePointCpm = false;
      this.form.chargeCpmPercent = null;
      this.form.chargePointCurrency = false;
      this.form.chargeCurrencyPercent = null;
    },
    resetPaymentForm() {
      this.form.paymentFlag = false;
      this.form.hasDatePayment = false;
      this.form.paymentPercent = null;
      this.form.paymentDate.start = '';
      this.form.paymentDate.end = '';
      this.form.paymentPeriodPercent = null;
      this.form.paymentPointCpm = false;
      this.form.paymentCpmPercent = null;
      this.form.paymentStoreFlag = false;
      this.form.isPaymentCurrency = false;
      this.form.paymentCurrencyPercent = null;
      this.form.timingDate = null;
    },
    resetChargeWithTime() {
      this.form.isCpmPercent = false;
      this.form.isManualPercent = false;
      this.form.isLimitPointAdd = false;
      this.form.isLimitPointAddPerPerson = false;
      this.form.timeDate.start = '';
      this.form.timeDate.end = '';
      this.form.chargeSpecialTimeCpmPercent = null;
      this.form.chargeSpecialTimeManualPercent = null;
      this.form.chargeSpecialTimePercent = null;
      this.form.limitPointAdd = null;
      this.form.limitPointAddPerPerson = null;
    },
    resetPaymentWithTime() {
      this.form.paymentPointSpecialFlag = false;
      this.form.isPaymentCpmPercent = false;
      this.form.paymentStoreSpecialFlag = false;
      this.form.paymentSpecialTimeCpmPercent = null;
      this.form.paymentSpecialTimePercent = null;
      this.form.paymentTimeDate.start = '';
      this.form.paymentTimeDate.end = '';
      this.form.isPaymentManualPercent = false;
      this.form.paymentSpecialTimeManualPercent = null;
      this.form.isLimitPointPaymentSpecial = false;
      this.form.limitPointPaymentSpecial = null;
      this.form.isLimitPointPaymentPerPerson = false;
      this.form.limitPointPaymentPerPerson = null;
    },
    resetRegistCustomer() {
      this.form.isRegistCpmCustomer = false;
      this.form.registCustomerAmount = null;
      this.form.registCpmCustomerAmount = null;
      this.form.isRegistCustomerDate = false;
      this.form.registCustomerDate.start = '';
      this.form.registCustomerDate.end = '';
    },
    handleDateCharge() {
      this.form.chargeDate.start = '';
      this.form.chargeDate.end = '';
      this.form.chargePeriodPercent = null;
    },
    handleDatePayment() {
      this.form.paymentDate.start = '';
      this.form.paymentDate.end = '';
      this.form.paymentPeriodPercent = null;
    },
    handleRegisterCustomerDate() {
      this.form.registCustomerDate.start = '';
      this.form.registCustomerDate.end = '';
    },
    tabChange: async function(num) {
      this.tabActive = num;
      if (this.isPointPage) {
        await this.getPointDetail();
        if (this.pointDetail?.id) {
          this.form = { ...this.form, ...this.pointDetail };
          this.checkFormData();
        }
      } else {
        this.formCategory.categoryName = '';
        this.getCategories();
      }
    },
    async updateCategory(id, name, index) {
      this.$message.reset();
      if (!name) {
        this.$set(this.errors, `categoryName${index}`, true);
        this.$message.showError('noCategoryName');
      } else {
        this.resetErrors(this.errors);
        const result = await this.$store.dispatch(`${this.storeModule}/updateCategory`, {
          subdomain: this.subdomain,
          categoryId: id,
          category: name
        });
        if (result) {
          this.$message.updated('pointCategory');
          this.getCategories();
          this.$refs.pagination.resetPagination();
        }
      }
    },
    async addCategory() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formCategory, 'formCategory');
        return false;
      } else if (this.isMaximumCategory) {
        this.$message.showError('maxCategory', 'max', this.isCommonAllEvent ? this.maxCategoryCommon : this.maxCategoryChild);
      } else {
        const result = await this.$store.dispatch(`${this.storeModule}/createCategory`, {
          subdomain: this.subdomain,
          category: this.formCategory.categoryName
        });
        if (result) {
          this.formCategory.categoryName = '';
          this.getCategories();
          this.$message.created('pointCategory');
          this.$refs.pagination.resetPagination();
        }
      }
    },
    handleDeleted(id) {
      this.categoryId = id;
      this.showModal();
    },
    async deleteCategory() {
      const result = await this.$store.dispatch(`${this.storeModule}/deleteCategory`, {
        subdomain: this.subdomain,
        categoryId: this.categoryId,
      });
      this.closeModal();
      if (result) {
        this.getCategories();
        this.$message.deleted('pointCategory');
      }
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
    formatForm() {
      return {
        ...this.form,
        chargeStartDate: this.form.chargeDate.start || '',
        chargeEndDate: this.form.chargeDate.end || '',
        paymentStartDate: this.form.paymentDate.start || '',
        paymentEndDate: this.form.paymentDate.end || '',
        timeStart: this.form.timeDate.start || '',
        timeEnd: this.form.timeDate.end || '',
        paymentTimeStart: this.form.paymentTimeDate.start || '',
        paymentTimeEnd: this.form.paymentTimeDate.end || '',
        registCustomerStartDate: this.form.registCustomerDate.start || '',
        registCustomerEndDate: this.form.registCustomerDate.end || '',
        paymentFlag: this.form.paymentFlag ? 1 : 0,
        paymentStoreFlag: this.form.paymentStoreFlag ? 1 : 0,
        paymentPointSpecialFlag: this.form.paymentPointSpecialFlag ? 1 : 0,
        paymentStoreSpecialFlag: this.form.paymentStoreSpecialFlag ? 1 : 0,
      }
    },
    checkFormData() {
      this.form.isCharge = this.form.chargePercent;
      this.form.hasDateCharge = this.form.chargeDate?.start && this.form.chargeDate?.end;
      this.form.chargePointCpm = this.form.chargeCpmPercent;
      this.form.chargePointCurrency = this.form.chargeCurrencyPercent;
      this.form.isPayment = this.form.paymentPercent;
      this.form.hasDatePayment = this.form.paymentDate?.start && this.form.paymentDate?.end;
      this.form.paymentPointCpm = this.form.paymentCpmPercent;
      this.form.isPaymentCurrency = this.form.paymentCurrencyPercent;
      this.form.isRegisterCustomer = this.form.registCustomerAmount;
      this.form.isRegistCpmCustomer = this.form.registCpmCustomerAmount;
      this.form.isChargeWithTime = this.form.timeDate?.start && this.form.timeDate?.end;
      this.form.isPaymentWithTime = this.form.paymentTimeDate?.start && this.form.paymentTimeDate?.end;
      this.form.isCpmPercent = !!this.form.chargeSpecialTimeCpmPercent;
      this.form.isManualPercent = !!this.form.chargeSpecialTimeManualPercent;
      this.form.isPaymentCpmPercent = !!this.form.paymentSpecialTimeCpmPercent;
      this.form.isLimitPointAdd = !!this.form.limitPointAdd;
      this.form.isLimitPointAddPerPerson = !!this.form.limitPointAddPerPerson;
      this.form.isPaymentManualPercent = !!this.form.paymentSpecialTimeManualPercent;
      this.form.isLimitPointPaymentSpecial = !!this.form.limitPointPaymentSpecial;
      this.form.isLimitPointPaymentPerPerson = !!this.form.limitPointPaymentPerPerson;
    },
    async handleSettingPoint() {
      await this.$store.dispatch('common/resetErrorFields');
      this.$v.$touch();
      if (this.$v.$invalid) {
        const formEvent = this.isPointCommon ? 'pointParentEvent' : 'pointChildEvent';
        this.getErrors(this.$v.form, formEvent);
        return false;
      } else {
        this.resetDateErrors();
        let result;
        const data = this.formatForm();
        delete data.isCharge;
        delete data.hasDateCharge;
        delete data.chargePointCpm;
        delete data.chargePointCurrency;
        delete data.isPayment;
        delete data.isPaymentCurrency;
        delete data.paymentPointCpm;
        delete data.hasDatePayment;
        delete data.isRegisterCustomer;
        delete data.isRegistCpmCustomer;
        delete data.isRegistCustomerDate;
        delete data.chargeDate;
        delete data.paymentDate;
        delete data.registCustomerDate;
        delete data.timeDate;
        delete data.paymentTimeDate;
        delete data.isChargeWithTime;
        delete data.isPaymentWithTime;
        delete data.isCpmPercent;
        delete data.isManualPercent;
        delete data.isPaymentCpmPercent;
        delete data.isPaymentManualPercent;
        delete data.isLimitPointAdd;
        delete data.isLimitPointAddPerPerson;
        delete data.isLimitPointPaymentSpecial;
        delete data.isLimitPointPaymentPerPerson;
        if (!this.pointDetail?.id) {
          result = await this.$store.dispatch(`${this.storeModule}/createSettingPoint`, data);
          if (result) {
            this.$message.created('point');
            await this.getPointDetail();
          }
        } else {
          result = await this.$store.dispatch(`${this.storeModule}/updateSettingPoint`, {
            ...data,
            pointId: this.pointDetail?.id
          });
          if (result) {
            this.$message.updated('point');
            await this.getPointDetail();
          }
        }
      }
    },
    async getCategories() {
      await this.$store.dispatch(`${this.storeModule}/getCategoriesList`, { subdomain: this.subdomain });
    },
    async getPointDetail() {
      await this.$store.dispatch(`${this.storeModule}/getPointDetail`);
    },
    getFormPointDetail() {
      this.form = {
        ...this.form,
        ...this.pointDetail,
        chargeDate: {
          ...this.form.chargeDate,
          start: this.pointDetail.chargeStartDate || '',
          end: this.pointDetail.chargeEndDate || '',
        },
        paymentDate: {
          ...this.form.paymentDate,
          start: this.pointDetail.paymentStartDate || '',
          end: this.pointDetail.paymentEndDate || '',
        },
        registCustomerDate: {
          ...this.form.registCustomerDate,
          start: this.pointDetail.registCustomerStartDate || '',
          end: this.pointDetail.registCustomerEndDate || '',
        },
        timeDate: {
          ...this.form.timeDate,
          start: this.pointDetail?.timeStart || '',
          end: this.pointDetail?.timeEnd || '',
        },
        paymentTimeDate: {
          ...this.form.paymentTimeDate,
          start: this.pointDetail?.paymentTimeStart || '',
          end: this.pointDetail?.paymentTimeEnd || '',
        }
      };
      this.checkFormData();
    },
    resetDateErrors() {
      this.errors = {
        chargeDate: {
          start: false,
          end: false,
        },
        paymentDate: {
          start: false,
          end: false,
        },
        registCustomerDate: {
          start: false,
          end: false,
        },
        timeDate: {
          start: false,
          end: false,
        },
        paymentTimeDate: {
          start: false,
          end: false,
        }
      };
    },
    checkValidDemical(val) {
      return val < 0.1 || val > 999.9 || !isDecimal(val);
    }
  },
  mounted() {
    const loading = this.$loading.show();
    this.initFormPoint();
    this.initFormCategory();
    Promise.all([
      this.getCategories(),
    ]).finally(() => {
      if (this.pointDetail?.id) {
        this.getFormPointDetail();
        this.form.timingDate = this.newTimingDate;
      }
      this.$loading.clear(loading);
    })
  }
};
</script>

<style scoped>
.custom-no-data {
  margin: 20px 0;
}
</style>
